import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  handleRecordingStarted,
 } from "../store/recording/actions";
import translate from "../services/translate";


const Recording = (props) => {
  const { isRecording, elapsed, isError } = useSelector((state) => state.recording);
  const dispatch = useDispatch();

  const startRecording = async () => {
    dispatch(await handleRecordingStarted(finished))
  }

  const finished = async (blob) => {
    props.finished(blob);
  }

  return (
    <Fragment>
      { isError ? 
          <Fragment>
            <p>{translate("Recording.error")}</p>
          </Fragment>

        :
          isRecording ? 
          <Fragment>
            <p>{translate("Recording.recording")}</p>
            <p className="timer">{10-Math.floor(elapsed / 1000)}</p>
          </Fragment>
         :
          <Fragment>
            <p>{translate("Recording.warn")}</p>
            <p>{translate("Recording.prompt")}</p>
            <p>{translate("Recording.time")}</p>
            <button className="submit" onClick={startRecording}>
              {translate("Recording.start")}
            </button>
          </Fragment>
    }
    </Fragment>
  );

}

Recording.propTypes = {
  finished: PropTypes.func,
};

export default Recording;