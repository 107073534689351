import React, { Fragment, useEffect } from "react";

import View from "../components/View";
import Mint from "../components/NoMetaMint";
import pink from '../assets/images/mirror-piece-pinktext.png';
import white from '../assets/images/mirror-piece-whitetext.png';

const Home = () => {
  useEffect(() => {
    const setup = async () => {
      try {
        let v = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          })
        console.log(v)
        let video = document.getElementById("video");
        video.srcObject = v;
        video.play()
        console.log("got video")
      } catch (err) {
        console.log(err);
        let video = document.getElementById("video");
        video.style.display = "none";
      }
    }
    setup()
  }, []);


  return (
    <Fragment>
      <View>
        <div id="main">
          <Mint />
        </div>
        <video id="video" autoPlay playsInline></video>
        <img className="pink" src={pink} alt="Mirror Piece"></img>
        <img className="white" src={white} alt="Mirror Piece"></img>
      </View>
    </Fragment>
  );
};


export default Home;