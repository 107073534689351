import logger from "redux-logger";
import thunk from "redux-thunk";
import api from "./api";

const middlewares = [thunk, api];


if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export default middlewares;
