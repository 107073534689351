import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./views/Home";

// prettier-ignore
const routes = () => (
  <Routes>
      <Route
        Component={Home}
        path="/"
      />
  </Routes>
);

export default routes;
