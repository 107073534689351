import detectEthereumProvider from "@metamask/detect-provider";

if (window.ethereum) {
  // https://docs.metamask.io/guide/ethereum-provider.html#ethereum-autorefreshonnetworkchange
  // window.ethereum.autoRefreshOnNetworkChange = false;
}

let provider;

export async function detectMetaMask() {
  provider = await detectEthereumProvider({
    mustBeMetaMask: true,
    timeout: 5000,
  });

  return provider;
}

export async function enableProvider() {
  if (!provider) {
    throw new Error("Provider not given");
  }

  const accounts = await provider.enable(); //request({ method: "eth_accounts" });
  const chainId = await provider.request({ method: "eth_chainId" });
 
  console.log(chainId);
  console.log(process.env.REACT_APP_CHAIN_ID);

  let error = {};

  if (chainId !== process.env.REACT_APP_CHAIN_ID) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID}],
      }); 
    } catch (err) {
      if (err) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x89', 
                chainName:'Polygon',
                rpcUrls:['https://polygon-rpc.com'],                   
                blockExplorerUrls:['https://polygonscan.com/'],  
                nativeCurrency: { 
                  symbol:'MATIC',   
                  decimals: 18
                }     
              }]
            });
          } catch (err) {
            console.log(err);
            error = err;
          }
        } else {
          console.log(err);
          error = err;
        }
      }
    }

  return {
    accounts,
    chainId,
    error,
  };
}
