import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import { useRequestForm } from "../hooks/form";
import { putRequest, postRequest } from "../store/api/actions";
import FormMint from "./FormMint";
import Recording from "./Recording";
import translate from "../services/translate";
import { useRequestId, useRequest } from "../hooks/request";
import ButtonSubmit from "./ButtonSubmit";
import Link from "../components/Link";

import "../styles/Mint.css";

const Mint = (props) => {
  const [account, setAccount] = useState(null);
  const dispatch = useDispatch();
  const mintRequestId = useRequestId();
  const recordingRequestId = useRequestId();
  const [isMintcodeError, setIsMintcodeError] = useState();
  const [mintcodeId, setMintcodeId] = useState("");
  //const [tokenInfo, setTokenInfo] = useState({});
  const [recordingFinished, setRecordingFinished] = useState(false);

  const finished = (recording) => {
    setRecordingFinished(true);
    const formData = new FormData();
    console.log(recording)
    console.log(mintcodeId)
    formData.append('mintcodeId', mintcodeId);
    const blob = new Blob(recording, {type: 'audio/wav'});
    formData.append('file', blob, 'blob.webm');
    dispatch(
      postRequest({
        id: recordingRequestId,
        endpoint: process.env.REACT_APP_API_URL,
        path: ["uploads", "recordings"],
        body: formData,
      })
    );
  }

  const { Form } = useRequestForm({
    onSubmit: async ({ key, mintcode, email }) => {
      dispatch(
        putRequest({
          id: mintRequestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: ["mirrors", "mintcodes"],
          body: {
            mintcode,
            email,
          },
        })
      );
    },
  });

  useRequest(mintRequestId, {
    onError: () => {
      setIsMintcodeError(true);
      console.log("error")
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setIsMintcodeError(true);
        console.log(response)
      } else {
        console.log("success")
        console.log(response)
        setAccount(response.data.account);
        setMintcodeId(response.data.mintcodeId);
      }
    },
  });

  useRequest(recordingRequestId, {
    onError: (err) => {
      console.log(err)
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        console.log(response)
      } else {
        console.log(response);
      }
    },
  });

  return (
    <Fragment>
    {
      recordingFinished ? 
        <Fragment>
          <p>{translate("Mint.confirmation")}</p>
          <p>{translate("Mint.confirmation2")}</p>
          <p>{translate("Mint.instructions")}
            <Link url="https://medium.com/publicaio/how-import-a-wallet-to-your-metamask-account-dcaba25e558d">here</Link>
          </p>
          <img className="token" alt="Mirror NFT" src="https://isthisa.computer/ipfs/QmZDD2vSxnBdLf6PgEmLqodG5LnSsXvp8WTWy7MRwQYJzk/mirror-piece-square.jpg"></img>
        </Fragment>
      :
        isMintcodeError ? 
          <Fragment>
              <p>{translate("Mint.emailError")}</p>
            </Fragment>
        : 
          !account ?
            <Form>
              <FormMint />
              <ButtonSubmit />
            </Form>
          :
            <div>
              <p>{translate("Mint.connected", { account })}</p>
              <Recording finished={finished} />
            </div>
    }
    </Fragment>
  )
};

Mint.propTypes = {};

export default Mint;
