import PropTypes from "prop-types";
import React from "react";
import translate from "../services/translate";
//import { useFormContext } from "react-final-form";

const ButtonSubmit = ({ children, isDisabled, ...rest }) => {
  //const { meta } = useFormContext();

  return (
    <button
      className="submit"
      //disabled={isDisabled || !meta.canSubmit}
      disabled={isDisabled}
      type="submit"
      {...rest}
    >
      {children || translate("default.buttonSubmit")}
    </button>
  );
};

ButtonSubmit.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
};

export default ButtonSubmit;
