import { combineReducers } from "redux";

import ethereum from "./ethereum/reducers";
import api from "./api/reducers";
import recording from "./recording/reducers";

const rootReducer = combineReducers({
  api,
  ethereum,
  recording,
});

export default rootReducer;
