import update from "immutability-helper";

import ActionTypes from "./types";

const initialState = {
  mediaRecorder: {},
  timeStarted: 0,
  recordedChunks: [],
  isRecording: false,
  elapsed: 10,
  isError: false,
};

const recodingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECORDING_STARTED:
      return update(state, {
        mediaRecorder: { $set: action.meta.mediaRecorder },
        timeStarted: { $set: Date.now() },
        isRecording: { $set: true },
        finished: { $set: action.meta.finished },
        isError: { $set: action.meta.isError },
      });
    case ActionTypes.RECORDING_UPDATED:
      return update(state, {
        recordedChunks: { $push: action.meta.newChunk },
        elapsed: { $set: action.meta.elapsed },
      });
    case ActionTypes.RECORDING_FINISHED:
      return update(state, {
        mediaRecorder: { $set: action.meta.mediaRecorder },
        isRecording: { $set: false },
      });
    default:
      return state;
  }
};

export default recodingReducer;
