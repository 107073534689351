import ActionTypes from "./types";
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

import store from '../../configureStore';

const recordingUpdated = (data) => {
  store.dispatch(handleRecordingUpdated(data));
}

export function handleRecordingUpdated(data) {
  const { recording } = store.getState();
  let newData;
  let elapsed;

  if (data.data.size > 0) {
    newData = data.data;
  }

  if ((Date.now() - recording.timeStarted) > 10 * 1000) {
    console.info("Record:: Limit Reached")
    store.dispatch(handleRecordingFinished())
  } else {
    elapsed = Date.now() - recording.timeStarted
    console.log("Record:: Elapsed", Math.round(elapsed / 1000))
  }
  return {
    type: ActionTypes.RECORDING_UPDATED,
    meta: {
      newChunk: [newData],
      elapsed,
    },
  };
}

export function handleRecordingFinished() {
  const { recording } = store.getState();
  recording.mediaRecorder.removeEventListener(
      'dataavailable',
      recordingUpdated
  );
  console.log(recording)
  recording.finished(recording.recordedChunks);
  return {
    type: ActionTypes.RECORDING_FINISHED,
    meta: {
      mediaRecorder: { ...recording.mediaRecorder },
    },
  };
}

export async function handleRecordingStarted(finished) {
  try {
    await register(await connect());
  } catch (e) {
    console.info("ignoring:",e)
  }
  try {
    const um = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: false,
        suppressLocalAudioPlayback: true,
      },
      video: false,
    })
    const options = {mimeType: 'audio/wav'};
    const mediaRecorder = new MediaRecorder(um, options);
    mediaRecorder.addEventListener(
        'dataavailable',
        recordingUpdated,
    );
    mediaRecorder.start(1000);
    return {
      type: ActionTypes.RECORDING_STARTED,
      meta: {
        mediaRecorder,
        finished,
      },
    };
  } catch (e) {
    return {
      type: ActionTypes.RECORDING_STARTED,
      meta: {
        isError: true,
        finished,
      },
    };
  }

}

