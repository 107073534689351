const components = {
  Mint: {
    connected: "Now connected as: {account}",
    emailError: `Could not validate password!`,
    confirmation: "Success! Your Mirror token was minted",
    confirmation2: "The 12 word key on your Echoes card can be used to set up a Metamask account. Your mirror NFT has already been sent there. Keep these words safe! You can use them next year to activate the token",
    opensea: "Once you install Metamask, you can also view your Mirror on ",
    instructions: "Need instructions? There are some "
  },
  Recording: {
    start: "Start",
    error: "Error enabling audio",
    recording: "Recording",
    warn: "This app will record audio for a collective performance in the future",
    prompt: "Think of something to say - a wish or sound you would like to hear reflected back or give onwards to others",
    time: "You will have 10 seconds",
  }
};

const views = {
  Home: {
    needMetamask: "You need to install Metamask, a popular blockchain wallet",
    link: "Here are some instructions"
  },
};

const copy = {
  title: "Mirror Piece",
  default: {
    buttonSubmit: "Submit",
  },
  ...components,
  ...views,
};

export default copy;
